import React from 'react';
import slugify from 'slugify';
import c from 'classnames';

import styles from './styles/HeroHeading.module.scss';
import typo from '../styles/typo.module.scss';

const HeroHeading = ({ text }) => {
  const id = slugify(text, { lower: true });

  return (
    <h1 id={id} className={c(styles.heading, typo.typoMega)}>
      {text}
    </h1>
  );
};

export default HeroHeading;
