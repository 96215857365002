import React, { useState, useEffect, useCallback } from 'react';
import Img from 'gatsby-image';
import c from 'classnames';
import { useSwipeable } from 'react-swipeable';

import Modal from './Modal';

import arrowIcon from '../img/arrow-right.svg';

import styles from './styles/GalleryModal.module.scss';
import Loader from './Loader';

const GalleryModal = ({ images, initialIndex, onRequestClose }) => {
  const [imageIndex, setImageIndex] = useState(initialIndex);
  const [isLoading, setIsLoading] = useState(true);
  const hasMultipleImages = images.length > 1;
  const currentImage = images[imageIndex];
  const canShowPrevious = imageIndex > 0;
  const canShowNext = imageIndex < images.length - 1;

  const trySetImageIndex = useCallback(
    (index) => {
      if (index !== imageIndex) {
        setImageIndex(index);
        setIsLoading(true);
      }
    },
    [imageIndex]
  );

  const showImageAtIndex = (index) => {
    trySetImageIndex(index);
  };

  const showPreviousImage = useCallback(() => {
    trySetImageIndex(Math.max(0, imageIndex - 1));
  }, [imageIndex]);

  const showNextImage = useCallback(() => {
    trySetImageIndex(Math.min(images.length - 1, imageIndex + 1));
  }, [imageIndex, images]);

  const onImageLoad = () => {
    setIsLoading(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: showNextImage,
    onSwipedRight: showPreviousImage,
  });

  useEffect(() => {
    const keyboardHandler = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          showPreviousImage();
          break;
        case 'ArrowRight':
          showNextImage();
          break;
      }
    };

    window.addEventListener('keydown', keyboardHandler);

    return () => {
      window.removeEventListener('keydown', keyboardHandler);
    };
  }, [showPreviousImage, showNextImage]);

  return (
    <Modal onRequestClose={onRequestClose}>
      <div className={styles.wrapper}>
        <div className={styles.imageContainer}>
          <picture>
            <source
              srcSet={currentImage.full.srcSet}
              sizes={currentImage.full.sizes}
            />
            <img
              src={currentImage.full.src}
              className={styles.image}
              {...swipeHandlers}
              onLoad={onImageLoad}
            />
          </picture>
          {canShowPrevious && (
            <button
              className={c(styles.arrow, styles.left)}
              onClick={showPreviousImage}
            >
              <img
                className={styles.arrowIcon}
                src={arrowIcon}
                alt="Previous"
              />
            </button>
          )}
          {canShowNext && (
            <button
              className={c(styles.arrow, styles.right)}
              onClick={showNextImage}
            >
              <img className={styles.arrowIcon} src={arrowIcon} alt="Next" />
            </button>
          )}
          {isLoading && <Loader />}
        </div>
        {hasMultipleImages && (
          <div className={styles.thumbsContainer}>
            {images.map((image, index) => (
              <div
                className={c(styles.thumbWrapper, {
                  [styles.selected]: index === imageIndex,
                })}
                key={index}
                onClick={() => showImageAtIndex(index)}
              >
                <Img className={styles.thumb} fixed={image.thumb} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GalleryModal;
