import React from 'react';
import c from 'classnames';

import styles from '../styles/Section.module.scss';

export default ({ children, module }) => {
  const className = c(styles.section, {
    [styles.emphasis]: module && module.visualStyle === 'Emphasized',
  });
  return <div className={className}>{children}</div>;
};
