import React from 'react';

import styles from './styles/Loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} />
      </div>
    </div>
  );
};

export default Loader;
