import React from 'react';

import styles from './styles/Cta.module.scss';
import Button from './Button';

const Cta = (props) => {
  return (
    <div className={styles.ctaWrapper}>
      <Button {...props} />
    </div>
  );
};

export default Cta;
