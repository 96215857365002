import React from 'react';

import HeroHeading from './HeroHeading';
import HTMLContent from './HTMLContent';
import ImageGrid from './ImageGrid';
import SocialIcons from './SocialIcons';
import WidthLimiter from './WidthLimiter';

import styles from './styles/CollectionGallery.module.scss';
import Cta from './Cta';

export default ({ collection, slice = false, socialIcons = true }) => {
  const images =
    slice > 0 ? collection.gallery.slice(0, slice) : collection.gallery;
  const areImagesSliced = images.length !== collection.gallery.length;
  const link = `/collections/${collection.slug}`;

  return (
    <WidthLimiter>
      <HeroHeading text={collection.title} />
      {socialIcons && (
        <div className={styles.socialIcons}>
          <SocialIcons />
        </div>
      )}
      <ImageGrid images={images} />
      {areImagesSliced && <Cta link={link} text="See the whole collection" />}
      {collection.description && (
        <div className={styles.description}>
          <HTMLContent html={collection.description.childMarkdownRemark.html} />
        </div>
      )}
    </WidthLimiter>
  );
};
