import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import crossIcon from '../img/cross.svg';

import styles from './styles/Modal.module.scss';

const Modal = ({ children, onRequestClose }) => {
  const rootEl = document.getElementById('modal-root');

  useEffect(() => {
    const keyboardHandler = (e) => {
      switch (e.key) {
        case 'Escape':
          onRequestClose();
          break;
      }
    };

    window.addEventListener('keydown', keyboardHandler);

    return () => {
      window.removeEventListener('keydown', keyboardHandler);
    };
  });

  return createPortal(
    <div className={styles.wrapper}>
      {children}
      <button className={styles.cross} onClick={onRequestClose}>
        <img src={crossIcon} className={styles.crossIcon} alt="Close" />
      </button>
    </div>,
    rootEl
  );
};

export default Modal;
