const scrollToOffset = (topOffset, { offset = 0 } = {}) => {
  const finalOffset = topOffset + offset;

  window.scrollTo({
    top: finalOffset,
    behavior: 'smooth',
  });

  return true;
};

const scrollToElement = (element, options) => {
  if (!element) {
    return false;
  }

  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;

  return scrollToOffset(elementPosition, options);
};

const scrollToSelector = (selector, options) => {
  const element = document.querySelector(selector);

  return scrollToElement(element, options);
};

export default {
  selector: scrollToSelector,
  element: scrollToElement,
  offset: scrollToOffset,
};
