import React, { useEffect, useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import GalleryModal from './GalleryModal';

import styles from './styles/ImageGrid.module.scss';

const resizeGridItem = (item, rowHeight, rowGap) => {
  const rowSpan = Math.round(
    (item.querySelector(`.${styles.content}`).getBoundingClientRect().height +
      rowGap) /
      (rowHeight + rowGap)
  );
  item.style.gridRowEnd = 'span ' + rowSpan;
};

export default ({ images }) => {
  const gridRef = useRef(null);
  const [aligned, setAligned] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInitImageIndex, setModalInitImageIndex] = useState(0);

  const scheduleResizeAllGridItems = () => {
    setAligned(false);
  };

  const [debouncedResizeAllGridItems] = useDebouncedCallback(
    scheduleResizeAllGridItems,
    200
  );

  const openModal = (imageIndex) => {
    setModalInitImageIndex(imageIndex);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const loadingQueue = new Set();
  const imageWrappers = images.map((image) => {
    let resolve;
    const promise = new Promise((res) => {
      resolve = res;
    });

    return {
      image,
      promise,
      onStartLoad() {
        loadingQueue.add(image);
      },
      onLoad() {
        loadingQueue.delete(image);

        if (loadingQueue.size === 0) {
          debouncedResizeAllGridItems();
        }
      },
    };
  });

  const resizeAllGridItems = () => {
    const rowHeight = parseInt(
      window
        .getComputedStyle(gridRef.current)
        .getPropertyValue('grid-auto-rows')
    );
    const rowGap = parseInt(
      window.getComputedStyle(gridRef.current).getPropertyValue('grid-row-gap')
    );

    gridRef.current.childNodes.forEach((item) => {
      resizeGridItem(item, rowHeight, rowGap);
    });
  };

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeAllGridItems);

    scheduleResizeAllGridItems();

    return () => {
      window.removeEventListener('resize', debouncedResizeAllGridItems);
    };
  }, []);

  useEffect(() => {
    if (!aligned) {
      resizeAllGridItems();
      setAligned(true);
    }
  }, [aligned]);

  return (
    <div
      className={`${styles.grid} ${aligned ? styles.aligned : ''}`}
      ref={gridRef}
    >
      {imageWrappers.map((wrapper, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.content} onClick={() => openModal(index)}>
            <Img
              className={styles.image}
              fluid={wrapper.image.preview}
              onStartLoad={wrapper.onStartLoad}
              onLoad={wrapper.onLoad}
              loading={'eager'}
            />
          </div>
        </div>
      ))}

      {isModalOpen && (
        <GalleryModal
          images={images}
          initialIndex={modalInitImageIndex}
          onRequestClose={closeModal}
        />
      )}
    </div>
  );
};

export const query = graphql`
  fragment ImageGridSizes on ContentfulAsset {
    thumb: fixed(height: 80) {
      ...GatsbyContentfulFixed_withWebp_noBase64
    }
    preview: fluid(maxWidth: 350) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    full: fluid(maxWidth: 800) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
`;
