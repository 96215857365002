import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import scrollTo from '../utils/scrollTo';

import styles from './styles/NavbarLinks.module.scss';

export default ({ location }) => {
  const data = useStaticQuery(graphql`
    query NavigationLinksQuery {
      contentfulNavigation(contentful_id: { eq: "64ZW4ED65Ib00LSEDg5nJA" }) {
        links {
          id
          text
          url
        }
      }
    }
  `);

  const internalLinkClickHandler = (e, url) => {
    const parsedURL = new URL(url, location.href);

    if (parsedURL.pathname === location.pathname) {
      e.preventDefault();

      const didScroll = scrollTo.selector(parsedURL.hash, { offset: -100 });

      if (didScroll) {
        window.history.replaceState(
          {},
          '',
          `${location.pathname}${parsedURL.hash}`
        );
      }
    }
  };

  return (
    <>
      {data.contentfulNavigation.links.map((link) => {
        const isLinkExternal = /^https?:\/\//.test(link.url);
        const linkTagContents = (
          <span className={styles.linkText}>{link.text}</span>
        );

        return isLinkExternal ? (
          <a
            className={styles.link}
            href={link.url}
            target="_blank"
            rel="noreferrer noopener"
            key={link.id}
          >
            {linkTagContents}
          </a>
        ) : (
          <Link
            to={link.url}
            className={styles.link}
            onClick={(e) => internalLinkClickHandler(e, link.url)}
            key={link.id}
          >
            {linkTagContents}
          </Link>
        );
      })}
    </>
  );
};
