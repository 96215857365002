import React from 'react';

import facebookIcon from '../img/social/facebook.svg';
import instagramIcon from '../img/social/instagram.svg';

import styles from './styles/SocialIcons.module.scss';

const icons = {
  facebook: {
    url: 'https://www.facebook.com/bakosovajosefina',
    icon: facebookIcon,
  },
  instagram: {
    url: 'https://www.instagram.com/josefinabakosova',
    icon: instagramIcon,
  },
};

const SocialIcons = () => {
  return (
    <div className={styles.wrapper}>
      {Object.keys(icons).map((key) => (
        <a
          href={icons[key].url}
          className={styles.item}
          rel="noreferrer"
          target="_blank"
          key={key}
        >
          <img className={styles.icon} src={icons[key].icon} alt={key} />
          <span className={styles.label}>{key}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
