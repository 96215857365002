import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import c from 'classnames';

import NavbarLinks from './NavbarLinks';
import WidthLimiter from './WidthLimiter';

import navbarLogo from '../img/navbar-logo.svg';

import styles from './styles/FixedNavbar.module.scss';

export default ({ hideUntil, location }) => {
  const shouldHideAt = (y) => y < hideUntil;
  const [hidden, setHidden] = useState(shouldHideAt(0));
  useEffect(() => {
    if (hideUntil) {
      const scrollHandler = (e) => {
        const shouldBeHidden = shouldHideAt(window.scrollY);

        if (shouldBeHidden !== hidden) {
          setHidden(shouldBeHidden);
        }
      };
      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  });

  const spacerClassName = c(styles.spacer, { [styles.willHide]: !!hideUntil });
  const wrapperClassName = c(styles.wrapper, { [styles.hidden]: hidden });

  return (
    <div className={spacerClassName}>
      <div className={wrapperClassName}>
        <WidthLimiter>
          <div className={styles.navbar}>
            <Link to="/" className={styles.logoLink}>
              <img
                src={navbarLogo}
                alt="Josefina Bakošová"
                className={styles.logo}
              />
            </Link>
            <div className={styles.navigation}>
              <NavbarLinks styles={styles} location={location} />
            </div>
          </div>
        </WidthLimiter>
      </div>
    </div>
  );
};
