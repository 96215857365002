import React from 'react';
import { Link } from 'gatsby';

import styles from './styles/Button.module.scss';

export default ({ onClick, href, text, link }) => {
  const buttonContent = <span className={styles.button}>{text}</span>;

  return href ? (
    <a href={href} className={styles.wrapper}>
      {buttonContent}
    </a>
  ) : link ? (
    <Link to={link} className={styles.wrapper}>
      {buttonContent}
    </Link>
  ) : (
    <button onClick={onClick} className={styles.wrapper}>
      {buttonContent}
    </button>
  );
};
